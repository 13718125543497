import { render, staticRenderFns } from "./TaskAdd.vue?vue&type=template&id=c4049c4a&scoped=true&"
import script from "./TaskAdd.vue?vue&type=script&lang=js&"
export * from "./TaskAdd.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./TaskAdd.vue?vue&type=style&index=1&id=c4049c4a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4049c4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ethan/Documents/Github/avosina/web/avosina-claims/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c4049c4a')) {
      api.createRecord('c4049c4a', component.options)
    } else {
      api.reload('c4049c4a', component.options)
    }
    module.hot.accept("./TaskAdd.vue?vue&type=template&id=c4049c4a&scoped=true&", function () {
      api.rerender('c4049c4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tasks/TaskAdd.vue"
export default component.exports