var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0" },
    [
      _c("avo-alert", {
        attrs: { avoAlerts: _vm.avoAlerts },
        on: {
          "update:avoAlerts": function ($event) {
            _vm.avoAlerts = $event
          },
          "update:avo-alerts": function ($event) {
            _vm.avoAlerts = $event
          },
        },
      }),
      _vm.screen
        ? _c(
            "b-row",
            {
              staticClass: "px-5 py-3 sticky-top",
              staticStyle: { background: "white", "z-index": "40" },
              attrs: { "align-v": "center", "align-h": "between" },
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { sm: "12", md: "6", lg: "5" },
                },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "task-search-input pl-1",
                        attrs: { type: "search", placeholder: "Search" },
                        on: {
                          keyup: _vm.debounceTasks,
                          search: _vm.debounceTasks,
                        },
                        model: {
                          value: _vm.taskSearch,
                          callback: function ($$v) {
                            _vm.taskSearch = $$v
                          },
                          expression: "taskSearch",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("span", { staticClass: "divider" }),
                          _c("span", { staticClass: "divider" }),
                          _c(
                            "b-dropdown",
                            {
                              ref: "dropdown",
                              staticClass: "dropdown filter-dropdown",
                              attrs: { boundary: "window" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c("b-icon", {
                                              staticClass: "mr-2",
                                              attrs: { icon: "plus-circle" },
                                            }),
                                            _vm._v(" Add filter "),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3013882632
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass:
                                    "d-flex flex-column align-items-start",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "20px",
                                        "font-weight": "300",
                                      },
                                    },
                                    [_vm._v("Filters")]
                                  ),
                                  _vm.hasPermission("assign_claims") &&
                                  _vm.workerId == null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "selecter-label" },
                                            [_vm._v("Assigned To")]
                                          ),
                                          _c("worker-multiselect", {
                                            attrs: {
                                              "allow-empty": true,
                                              "allow-unassigned": true,
                                            },
                                            model: {
                                              value: _vm.filters.workers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "workers",
                                                  $$v
                                                )
                                              },
                                              expression: "filters.workers",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.hasPermission("assign_claims") &&
                                  _vm.workerId != null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "selecter-label" },
                                            [_vm._v("Claim")]
                                          ),
                                          _c("multiselect", {
                                            attrs: {
                                              "show-labels": false,
                                              options: _vm.worker_claims,
                                              multiple: true,
                                              internalSearch: true,
                                              placeholder:
                                                "-- Search for claims --",
                                            },
                                            model: {
                                              value: _vm.filters.claims,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "claims",
                                                  $$v
                                                )
                                              },
                                              expression: "filters.claims",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Due Date After"),
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "date",
                                      "today-button": "",
                                      "reset-button": "",
                                      "close-button": "",
                                    },
                                    model: {
                                      value: _vm.filters.date_start,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "date_start", $$v)
                                      },
                                      expression: "filters.date_start",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Due Date Before"),
                                  ]),
                                  _c("b-form-input", {
                                    staticClass: "mb-4",
                                    attrs: {
                                      type: "date",
                                      "today-button": "",
                                      "reset-button": "",
                                      "close-button": "",
                                    },
                                    model: {
                                      value: _vm.filters.date_end,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "date_end", $$v)
                                      },
                                      expression: "filters.date_end",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end mt-4",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-basic-btn mr-2",
                                          attrs: { variant: "outline-primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearFilters()
                                            },
                                          },
                                        },
                                        [_vm._v("Clear Filters")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-primary-btn",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.applyFilters },
                                        },
                                        [_vm._v("Apply")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "3", lg: "4" } },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "d-flex",
                      attrs: { pills: "" },
                      model: {
                        value: _vm.completionFilter,
                        callback: function ($$v) {
                          _vm.completionFilter = $$v
                        },
                        expression: "completionFilter",
                      },
                    },
                    [
                      _c("b-tab", {
                        attrs: { title: "Incomplete", active: "" },
                      }),
                      _c("b-tab", { attrs: { title: "Complete" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-right",
                  attrs: { sm: "12", md: "2", lg: "3" },
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.new-task",
                          modifiers: { "new-task": true },
                        },
                      ],
                      staticClass: "avo-primary-btn",
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "plus" },
                      }),
                      _vm._v("Add Task "),
                    ],
                    1
                  ),
                  _c("task-add", {
                    attrs: {
                      "claim-id": _vm.claimId,
                      issues: _vm.issues,
                      "worker-id": _vm.workerId,
                    },
                    on: {
                      submitted: function ($event) {
                        return _vm.getTasks()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.embedded
        ? _c("b-card", { staticClass: "header-card" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row justify-content-between" },
              [
                _c("div", { staticClass: "summary-card-header-txt pl-2" }, [
                  _vm._v(" Tasks "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "avo-text-light pr-2 mt-1 cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("viewAllClicked")
                      },
                    },
                  },
                  [
                    _vm._v(" View all Tasks "),
                    _c("b-icon", { attrs: { icon: "chevron-right" } }),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "b-card",
        { class: _vm.embedded ? "body-card" : "body-card-screen" },
        [
          _c(
            "b-overlay",
            {
              staticStyle: { "min-height": "100px" },
              attrs: { show: _vm.tasksLoading, variant: "white" },
            },
            [
              _vm.tasks.length > 0
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.tasks, function (task, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _vm.completionFilter == task.state
                              ? _c(
                                  "b-card",
                                  {
                                    class: [
                                      _vm.embedded
                                        ? "task-card-small"
                                        : "task-card",
                                      { shadow: !_vm.taskData },
                                      { "mx-3": _vm.taskData },
                                    ],
                                  },
                                  [
                                    _vm.screen
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass: "cursor-pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectTask(index)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-iconstack",
                                                      {
                                                        staticClass:
                                                          "mr-3 mb-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.transitionTask(
                                                              task,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          staticClass:
                                                            "check-circle-fill",
                                                          attrs: {
                                                            "font-scale": "1.5",
                                                            icon: "circle-fill",
                                                          },
                                                        }),
                                                        _c("b-icon", {
                                                          staticClass:
                                                            "check-circle",
                                                          attrs: {
                                                            "font-scale": "1.5",
                                                            icon: "circle",
                                                          },
                                                        }),
                                                        task.state == 1
                                                          ? _c("b-icon", {
                                                              attrs: {
                                                                "shift-h":
                                                                  "1.5",
                                                                "shift-v": "-2",
                                                                "font-scale":
                                                                  "1.25",
                                                                icon: "check",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        style: [
                                                          {
                                                            fontSize: _vm.screen
                                                              ? "16px"
                                                              : "14px",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(task.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "avo-text-light text-right",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "p-0 text-left",
                                                        attrs: { cols: "3" },
                                                      },
                                                      [
                                                        _vm.dueDateClass(
                                                          task
                                                        ) == "past-due"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  _vm.dueDateClass(
                                                                    task
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Past Due"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm.dueDateClass(
                                                              task
                                                            ) == "soon-due"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  _vm.dueDateClass(
                                                                    task
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Due Soon "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "p-0 text-left",
                                                        attrs: { cols: "5" },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          staticClass: "mx-1",
                                                          attrs: {
                                                            icon: "calendar",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatFullDate(
                                                              task.due_date
                                                            )
                                                          ) + " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "p-0 text-left",
                                                        attrs: { cols: "3" },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          staticClass: "mx-1",
                                                          attrs: {
                                                            icon: "clock",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatTime(
                                                              task.due_date
                                                            )
                                                          ) + " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "p-0 text-left",
                                                        attrs: { cols: "1" },
                                                      },
                                                      [
                                                        !_vm.taskData
                                                          ? _c("b-icon", {
                                                              directives: [
                                                                {
                                                                  name: "b-modal",
                                                                  rawName:
                                                                    "v-b-modal",
                                                                  value:
                                                                    "edit-task" +
                                                                    (_vm.screen
                                                                      ? "-screen"
                                                                      : "-summary"),
                                                                  expression:
                                                                    "'edit-task'+ (screen ? '-screen': '-summary')",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "mx-1 cursor-pointer",
                                                              attrs: {
                                                                icon: "three-dots-vertical",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.setTaskToEdit(
                                                                      task
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "b-row",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectTask(index)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "avo-text-light-small d-flex justify-content-between align-items-center mb-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("b-icon", {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                icon: "calendar",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatFullDate(
                                                                  task.due_date
                                                                )
                                                              ) + " "
                                                            ),
                                                            _c("b-icon", {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                icon: "clock",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatTime(
                                                                  task.due_date
                                                                )
                                                              ) + " "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          [
                                                            _vm.dueDateClass(
                                                              task
                                                            ) == "past-due"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      _vm.dueDateClass(
                                                                        task
                                                                      ),
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Past Due"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm.dueDateClass(
                                                                  task
                                                                ) == "soon-due"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      _vm.dueDateClass(
                                                                        task
                                                                      ),
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Due Soon "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            !_vm.taskData
                                                              ? _c("b-icon", {
                                                                  directives: [
                                                                    {
                                                                      name: "b-modal",
                                                                      rawName:
                                                                        "v-b-modal",
                                                                      value:
                                                                        "edit-task" +
                                                                        (_vm.screen
                                                                          ? "-screen"
                                                                          : "-summary"),
                                                                      expression:
                                                                        "'edit-task'+ (screen ? '-screen': '-summary')",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "ml-4 cursor-pointer",
                                                                  attrs: {
                                                                    icon: "three-dots-vertical",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.setTaskToEdit(
                                                                          task
                                                                        )
                                                                      },
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-start align-items-center",
                                                      },
                                                      [
                                                        !_vm.taskData
                                                          ? _c(
                                                              "b-iconstack",
                                                              {
                                                                staticClass:
                                                                  "mr-3 mb-2",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.transitionTask(
                                                                        task,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  staticClass:
                                                                    "check-circle-fill",
                                                                  attrs: {
                                                                    "font-scale":
                                                                      "1.5",
                                                                    icon: "circle-fill",
                                                                  },
                                                                }),
                                                                _c("b-icon", {
                                                                  staticClass:
                                                                    "check-circle",
                                                                  attrs: {
                                                                    "font-scale":
                                                                      "1.5",
                                                                    icon: "circle",
                                                                  },
                                                                }),
                                                                task.state == 1
                                                                  ? _c(
                                                                      "b-icon",
                                                                      {
                                                                        attrs: {
                                                                          "shift-h":
                                                                            "1.5",
                                                                          "shift-v":
                                                                            "-2",
                                                                          "font-scale":
                                                                            "1.25",
                                                                          icon: "check",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "500",
                                                                  "margin-left":
                                                                    "16px",
                                                                  "margin-right":
                                                                    "8px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "#" +
                                                                    _vm._s(
                                                                      task.claim_id
                                                                    ) +
                                                                    " -"
                                                                ),
                                                              ]
                                                            ),
                                                        _c(
                                                          "span",
                                                          {
                                                            style: [
                                                              {
                                                                fontSize:
                                                                  _vm.screen
                                                                    ? "16px"
                                                                    : "14px",
                                                              },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(task.title)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                    _vm.clicked[index]
                                      ? _c(
                                          "div",
                                          {
                                            class: [
                                              _vm.embedded
                                                ? "task-body-small"
                                                : "task-body",
                                            ],
                                            style: [
                                              {
                                                fontSize: _vm.screen
                                                  ? "16px"
                                                  : "14px",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(task.body) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.clicked[index]
                                      ? _c(
                                          "div",
                                          {
                                            class: [
                                              _vm.embedded
                                                ? "worker-select-small"
                                                : "worker-select",
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  "mr-4": _vm.screen,
                                                  "mr-1": _vm.embedded,
                                                  "d-flex": true,
                                                  "flex-column": true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "label-text",
                                                    style: [
                                                      {
                                                        fontSize: _vm.screen
                                                          ? "14px"
                                                          : "12px",
                                                      },
                                                    ],
                                                  },
                                                  [_vm._v("Assigned to")]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "data-card" },
                                                  [
                                                    _c(
                                                      "b-iconstack",
                                                      {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          color: "#519eff",
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "circle",
                                                            scale: "1.8",
                                                          },
                                                        }),
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "person",
                                                            scale: "1.2",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        style: [
                                                          {
                                                            fontSize: _vm.screen
                                                              ? "14px"
                                                              : "12px",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(task.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  "mr-4": _vm.screen,
                                                  "mr-1": _vm.embedded,
                                                  "d-flex": true,
                                                  "flex-column": true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "label-text",
                                                    style: [
                                                      {
                                                        fontSize: _vm.screen
                                                          ? "14px"
                                                          : "12px",
                                                      },
                                                    ],
                                                  },
                                                  [_vm._v("Added on")]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "data-card" },
                                                  [
                                                    _c(
                                                      "b-iconstack",
                                                      {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          color: "#519eff",
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "circle",
                                                            scale: "1.8",
                                                          },
                                                        }),
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "calendar-4",
                                                            scale: "1",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        style: [
                                                          {
                                                            fontSize: _vm.screen
                                                              ? "14px"
                                                              : "12px",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatDateShort(
                                                                task.created
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "label-text",
                                                    style: [
                                                      {
                                                        fontSize: _vm.screen
                                                          ? "14px"
                                                          : "12px",
                                                      },
                                                    ],
                                                  },
                                                  [_vm._v("Claim")]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "data-card" },
                                                  [
                                                    _c(
                                                      "b-iconstack",
                                                      {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          color: "#519eff",
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "circle",
                                                            scale: "1.8",
                                                          },
                                                        }),
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "file-earmark-medical",
                                                            scale: "1",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        style: [
                                                          {
                                                            fontSize: _vm.screen
                                                              ? "14px"
                                                              : "12px",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              task.claim_id ||
                                                                "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      !_vm.taskData
                        ? _c("task-edit", {
                            attrs: {
                              screen: _vm.screen,
                              task: _vm.taskToEdit,
                              issues: _vm.issues,
                            },
                            on: {
                              submitted: function ($event) {
                                return _vm.getTasks()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.tasks.length == 0 && !_vm.tasksLoading
                ? _c("div", { staticClass: "no-tasks pt-4" }, [
                    _vm._v(" No tasks found "),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }