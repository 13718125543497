var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "new-worklog",
        title: "Log Work",
        "no-close-on-backdrop": "",
        scrollable: "",
        "hide-footer": "",
        size: "lg",
      },
      on: { hide: _vm.resetModal },
    },
    [
      _c(
        "div",
        { staticClass: "p-3" },
        [
          _c(
            "b-card",
            { staticClass: "claim-preview-card mb-3" },
            [
              _c(
                "b-row",
                {},
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v("CLAIM ID"),
                      ]),
                      _c("div", { staticClass: "data-text" }, [
                        _vm._v(_vm._s(_vm.claim.claim_id)),
                      ]),
                    ]),
                  ]),
                  _c("b-col", { staticClass: "px-0", attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v("PAYER"),
                      ]),
                      _c("div", { staticClass: "data-text" }, [
                        _vm._v(_vm._s(_vm.claim.payer_name)),
                      ]),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v("STATUS"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-items-center",
                          staticStyle: { "margin-left": "-10px" },
                        },
                        [
                          _c("div", {
                            class: ["status-icon", _vm.claim.state.short],
                          }),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.state.label) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("hr"),
              _c(
                "b-row",
                {},
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v("PATIENT"),
                      ]),
                      _c("div", { staticClass: "data-text" }, [
                        _vm._v(_vm._s(_vm.claim.patient_name)),
                      ]),
                    ]),
                  ]),
                  _c("b-col", { staticClass: "px-0", attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v("PROVIDER"),
                      ]),
                      _c("div", { staticClass: "data-text" }, [
                        _vm._v(_vm._s(_vm.claim.provider_name)),
                      ]),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v("SUBSTATUS"),
                      ]),
                      _c("div", { staticClass: "data-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.claim.substatus_1 && _vm.optionsMap
                              ? _vm.optionsMap.claim_substatuses[
                                  _vm.claim.substatus_1
                                ]
                              : "N/A"
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-form-group",
                [
                  _c("label", { staticClass: "selecter-label" }, [
                    _vm._v("Type of Work"),
                  ]),
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.optionsMapItem("worklog_types"),
                      state: _vm.fieldStates("work_type", "work_type"),
                      required: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function () {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null, disabled: "" } },
                              [_vm._v("-- Select a work type --")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.worklog.work_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.worklog, "work_type", $$v)
                      },
                      expression: "worklog.work_type",
                    },
                  }),
                ],
                1
              ),
              _vm.worklog.work_type
                ? _c("div", [
                    _c("div", [
                      _vm._v(" Claim Status will become: "),
                      _c("strong", [_vm._v(_vm._s(_vm.worklogClaimStateName))]),
                    ]),
                    _c("hr"),
                    _vm.workTypeName == "Claim Submitted"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Where?"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: {
                                      state: _vm.fieldStates(
                                        "submittal",
                                        "submittal_type"
                                      ),
                                      value: "1",
                                      inline: "",
                                    },
                                    model: {
                                      value: _vm.worklog.submittal_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "submittal_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.submittal_type",
                                    },
                                  },
                                  [_vm._v("Clearing house")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: {
                                      state: _vm.fieldStates(
                                        "submittal",
                                        "submittal_type"
                                      ),
                                      value: "0",
                                      inline: "",
                                    },
                                    model: {
                                      value: _vm.worklog.submittal_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "submittal_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.submittal_type",
                                    },
                                  },
                                  [_vm._v("Payer")]
                                ),
                              ],
                              1
                            ),
                            _vm.worklog.submittal_type == 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Select Payer")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("payer-multiselect", {
                                          attrs: {
                                            multiple: false,
                                            "allow-empty": false,
                                          },
                                          model: {
                                            value:
                                              _vm.multiselectVueModels.payer,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.multiselectVueModels,
                                                "payer",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "multiselectVueModels.payer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payer-addr" },
                                      },
                                      [_vm._v("Payer Address")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: { id: "payer-addr" },
                                          model: {
                                            value:
                                              _vm.worklog.payer_mailing_addr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_mailing_addr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.payer_mailing_addr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.submittal_type == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Clearinghouse Name")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.worklog.clearinghouse_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "clearinghouse_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.clearinghouse_name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Called Insurance"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Select Payer"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("payer-multiselect", {
                                  attrs: {
                                    multiple: false,
                                    "allow-empty": false,
                                  },
                                  model: {
                                    value: _vm.multiselectVueModels.payer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.multiselectVueModels,
                                        "payer",
                                        $$v
                                      )
                                    },
                                    expression: "multiselectVueModels.payer",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Payer Phone"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.worklog.payer_phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "payer_phone", $$v)
                                    },
                                    expression: "worklog.payer_phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Rep Name")]
                                    ),
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.worklog.rep_name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.worklog, "rep_name", $$v)
                                        },
                                        expression: "worklog.rep_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Reference Number")]
                                    ),
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.worklog.reference_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog,
                                            "reference_number",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.reference_number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Issue"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { placeholder: "What was the issue?" },
                                  model: {
                                    value: _vm.worklog.issue_text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "issue_text", $$v)
                                    },
                                    expression: "worklog.issue_text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Resolution"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "What action was taken/needs to be taken?",
                                  },
                                  model: {
                                    value: _vm.worklog.resolution,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "resolution", $$v)
                                    },
                                    expression: "worklog.resolution",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "EOB Retrieved"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Received By"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Payer Fax")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Payer Mail")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "2", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Payer Portal")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "avo-accordian-container my-3" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    class: [
                                      _vm.docsAccordianVisible
                                        ? "avo-accordian-open-btn"
                                        : "avo-accordian-btn",
                                    ],
                                    attrs: {
                                      variant: "outline-primary",
                                      "aria-expanded": _vm.docsAccordianVisible
                                        ? "true"
                                        : "false",
                                      "aria-controls": "docs-collapse",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.docsAccordianVisible =
                                          !_vm.docsAccordianVisible
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("Attachments")]),
                                    !_vm.docsAccordianVisible
                                      ? _c("b-icon", {
                                          attrs: { icon: "paperclip" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "x", scale: "1.25" },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: { id: "docs-collapse" },
                                    model: {
                                      value: _vm.docsAccordianVisible,
                                      callback: function ($$v) {
                                        _vm.docsAccordianVisible = $$v
                                      },
                                      expression: "docsAccordianVisible",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "docs-accordian" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column p-2",
                                          },
                                          [
                                            !_vm.selectedDoc
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "selecter-label",
                                                      },
                                                      [_vm._v("Upload EOB")]
                                                    ),
                                                    _c(
                                                      "b-form-group",
                                                      [
                                                        _c("b-form-file", {
                                                          attrs: {
                                                            state: Boolean(
                                                              _vm.file
                                                            ),
                                                            placeholder:
                                                              "Choose a file or drop it here...",
                                                            "drop-placeholder":
                                                              "Drop file here...",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "file-name",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var names =
                                                                    ref.names
                                                                  return [
                                                                    _c(
                                                                      "b-badge",
                                                                      {
                                                                        staticClass:
                                                                          "document-badge",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            names[0]
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1269243100
                                                          ),
                                                          model: {
                                                            value: _vm.file,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.file = $$v
                                                            },
                                                            expression: "file",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !_vm.file
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "selecter-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Choose from existing attachments"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("multiselect", {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        options: _vm.claimDocs,
                                                        "show-labels": false,
                                                        placeholder:
                                                          "-- Select an Option --",
                                                        "track-by":
                                                          "document_id",
                                                        label: "filename",
                                                      },
                                                      model: {
                                                        value: _vm.selectedDoc,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedDoc = $$v
                                                        },
                                                        expression:
                                                          "selectedDoc",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-amount" },
                                      },
                                      [_vm._v("Amount")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "payment-amount",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "amount"
                                        ),
                                      },
                                      model: {
                                        value: _vm.worklog.payment.amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "amount",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.payment.amount",
                                      },
                                    }),
                                    _c("b-form-invalid-feedback", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fieldErrMessages(
                                            "payment",
                                            "amount"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-date" },
                                      },
                                      [_vm._v("Payment Date")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "date",
                                        id: "payment-date",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "payment_date"
                                        ),
                                        placeholder: "",
                                        "value-as-datetime": "",
                                        "date-format-options": {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                        },
                                        "today-button": "",
                                        "reset-button": "",
                                        "close-button": "",
                                      },
                                      model: {
                                        value: _vm.worklog.payment.payment_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "payment_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "worklog.payment.payment_date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-invoice-no" },
                                      },
                                      [_vm._v("Check/EFT No.")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "payment-invoice-no",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "invoice_no"
                                        ),
                                      },
                                      model: {
                                        value: _vm.worklog.payment.invoice_no,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "invoice_no",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "worklog.payment.invoice_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Payment Status"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.posted,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.worklog, "posted", $$v)
                                      },
                                      expression: "worklog.posted",
                                    },
                                  },
                                  [_vm._v("Posted")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.posted,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.worklog, "posted", $$v)
                                      },
                                      expression: "worklog.posted",
                                    },
                                  },
                                  [_vm._v("Not Yet Posted")]
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    placeholder:
                                      "Was the EOB given to the poster to post?",
                                  },
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Patient Payment Received"
                      ? _c(
                          "div",
                          [
                            _vm.worklog.payment.payment_source == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "selecter-label" },
                                          [_vm._v("Payment type")]
                                        ),
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.optionsMapItem(
                                              "patient_payment_types"
                                            ),
                                            state: _vm.fieldStates(
                                              "payment",
                                              "patient_payment_type"
                                            ),
                                            required: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "first",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "b-form-select-option",
                                                      {
                                                        attrs: {
                                                          value: null,
                                                          disabled: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "-- Select a payment type --"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3162075507
                                          ),
                                          model: {
                                            value:
                                              _vm.worklog.payment
                                                .patient_payment_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog.payment,
                                                "patient_payment_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.payment.patient_payment_type",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "selecter-label" },
                                          [_vm._v("Payment method")]
                                        ),
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.optionsMapItem(
                                              "patient_payment_methods"
                                            ),
                                            state: _vm.fieldStates(
                                              "payment",
                                              "patient_payment_method"
                                            ),
                                            required: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "first",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "b-form-select-option",
                                                      {
                                                        attrs: {
                                                          value: null,
                                                          disabled: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "-- Select a payment method --"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            574007796
                                          ),
                                          model: {
                                            value:
                                              _vm.worklog.payment
                                                .patient_payment_method,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog.payment,
                                                "patient_payment_method",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.payment.patient_payment_method",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-amount" },
                                      },
                                      [_vm._v("Amount")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "payment-amount",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "amount"
                                        ),
                                      },
                                      model: {
                                        value: _vm.worklog.payment.amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "amount",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.payment.amount",
                                      },
                                    }),
                                    _c("b-form-invalid-feedback", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fieldErrMessages(
                                            "payment",
                                            "amount"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-date" },
                                      },
                                      [_vm._v("Date")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "date",
                                        id: "payment-date",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "payment_date"
                                        ),
                                        placeholder: "",
                                        "value-as-datetime": "",
                                        "date-format-options": {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                        },
                                        "today-button": "",
                                        "reset-button": "",
                                        "close-button": "",
                                      },
                                      model: {
                                        value: _vm.worklog.payment.payment_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "payment_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "worklog.payment.payment_date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-invoice-no" },
                                      },
                                      [_vm._v("Invoice No")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "payment-invoice-no",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "invoice_no"
                                        ),
                                      },
                                      model: {
                                        value: _vm.worklog.payment.invoice_no,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "invoice_no",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "worklog.payment.invoice_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Medical Records Sent"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Select Payer"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("payer-multiselect", {
                                  attrs: {
                                    multiple: false,
                                    "allow-empty": false,
                                  },
                                  model: {
                                    value: _vm.multiselectVueModels.payer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.multiselectVueModels,
                                        "payer",
                                        $$v
                                      )
                                    },
                                    expression: "multiselectVueModels.payer",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Method"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Faxed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Mailed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "2", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Web Portal")]
                                ),
                              ],
                              1
                            ),
                            _vm.worklog.payer_contact_type == 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Fax Number")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_fax,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_fax",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_fax",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Mailing Address")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.worklog.payer_mailing_addr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_mailing_addr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.payer_mailing_addr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Portal")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_portal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_portal",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_portal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "avo-accordian-container my-3" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    class: [
                                      _vm.docsAccordianVisible
                                        ? "avo-accordian-open-btn"
                                        : "avo-accordian-btn",
                                    ],
                                    attrs: {
                                      variant: "outline-primary",
                                      "aria-expanded": _vm.docsAccordianVisible
                                        ? "true"
                                        : "false",
                                      "aria-controls": "docs-collapse",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.docsAccordianVisible =
                                          !_vm.docsAccordianVisible
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("Attachments")]),
                                    !_vm.docsAccordianVisible
                                      ? _c("b-icon", {
                                          attrs: { icon: "paperclip" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "x", scale: "1.25" },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: { id: "docs-collapse" },
                                    model: {
                                      value: _vm.docsAccordianVisible,
                                      callback: function ($$v) {
                                        _vm.docsAccordianVisible = $$v
                                      },
                                      expression: "docsAccordianVisible",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "docs-accordian" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column p-2",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "selecter-label" },
                                              [_vm._v("Upload Records")]
                                            ),
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-file", {
                                                  attrs: {
                                                    multiple: "",
                                                    placeholder:
                                                      "Select file(s) or drop them here...",
                                                    "drop-placeholder":
                                                      "Drop file here...",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "file-name",
                                                        fn: function (ref) {
                                                          var names = ref.names
                                                          return [
                                                            _c(
                                                              "b-badge",
                                                              {
                                                                staticClass:
                                                                  "document-badge",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    names[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            names.length > 1
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "document-badge ml-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " + " +
                                                                        _vm._s(
                                                                          names.length -
                                                                            1 ==
                                                                            1
                                                                            ? "1 more file"
                                                                            : names.length -
                                                                                1 +
                                                                                " more files"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2765966228
                                                  ),
                                                  model: {
                                                    value: _vm.files,
                                                    callback: function ($$v) {
                                                      _vm.files = $$v
                                                    },
                                                    expression: "files",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              { staticClass: "selecter-label" },
                                              [
                                                _vm._v(
                                                  "Choose from existing attachments"
                                                ),
                                              ]
                                            ),
                                            _c("multiselect", {
                                              staticClass: "mb-3",
                                              attrs: {
                                                "hide-selected": true,
                                                options: _vm.claimDocs,
                                                multiple: true,
                                                "show-labels": false,
                                                placeholder:
                                                  "-- Select an Option --",
                                                "track-by": "document_id",
                                                label: "filename",
                                              },
                                              model: {
                                                value: _vm.selectedDocs,
                                                callback: function ($$v) {
                                                  _vm.selectedDocs = $$v
                                                },
                                                expression: "selectedDocs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Appealed Claim"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Select Payer"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("payer-multiselect", {
                                  attrs: {
                                    multiple: false,
                                    "allow-empty": false,
                                  },
                                  model: {
                                    value: _vm.multiselectVueModels.payer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.multiselectVueModels,
                                        "payer",
                                        $$v
                                      )
                                    },
                                    expression: "multiselectVueModels.payer",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Method"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Faxed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Mailed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "2", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Web Portal")]
                                ),
                              ],
                              1
                            ),
                            _vm.worklog.payer_contact_type == 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Fax Number")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_fax,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_fax",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_fax",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Mailing Address")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.worklog.payer_mailing_addr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_mailing_addr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.payer_mailing_addr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Portal")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_portal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_portal",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_portal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Issue"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "What is being appealed?",
                                  },
                                  model: {
                                    value: _vm.worklog.issue_text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "issue_text", $$v)
                                    },
                                    expression: "worklog.issue_text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Resolution"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "What is the resolution goal (i.e. “Payment of 37244”)",
                                  },
                                  model: {
                                    value: _vm.worklog.resolution,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "resolution", $$v)
                                    },
                                    expression: "worklog.resolution",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "avo-accordian-container my-3" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    class: [
                                      _vm.docsAccordianVisible
                                        ? "avo-accordian-open-btn"
                                        : "avo-accordian-btn",
                                    ],
                                    attrs: {
                                      variant: "outline-primary",
                                      "aria-expanded": _vm.docsAccordianVisible
                                        ? "true"
                                        : "false",
                                      "aria-controls": "docs-collapse",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.docsAccordianVisible =
                                          !_vm.docsAccordianVisible
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("Attachments")]),
                                    !_vm.docsAccordianVisible
                                      ? _c("b-icon", {
                                          attrs: { icon: "paperclip" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "x", scale: "1.25" },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: { id: "docs-collapse" },
                                    model: {
                                      value: _vm.docsAccordianVisible,
                                      callback: function ($$v) {
                                        _vm.docsAccordianVisible = $$v
                                      },
                                      expression: "docsAccordianVisible",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "docs-accordian" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column p-2",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "selecter-label" },
                                              [
                                                _vm._v(
                                                  "Upload appeal/reconsideration forms"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-file", {
                                                  attrs: {
                                                    multiple: "",
                                                    placeholder:
                                                      "Select file(s) or drop them here...",
                                                    "drop-placeholder":
                                                      "Drop file here...",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "file-name",
                                                        fn: function (ref) {
                                                          var names = ref.names
                                                          return [
                                                            _c(
                                                              "b-badge",
                                                              {
                                                                staticClass:
                                                                  "document-badge",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    names[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            names.length > 1
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "document-badge ml-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " + " +
                                                                        _vm._s(
                                                                          names.length -
                                                                            1 ==
                                                                            1
                                                                            ? "1 more file"
                                                                            : names.length -
                                                                                1 +
                                                                                " more files"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2765966228
                                                  ),
                                                  model: {
                                                    value: _vm.files,
                                                    callback: function ($$v) {
                                                      _vm.files = $$v
                                                    },
                                                    expression: "files",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "selecter-label",
                                                attrs: {
                                                  for: "hcfa-attachments-dropdown",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Choose from existing documents"
                                                ),
                                              ]
                                            ),
                                            _c("multiselect", {
                                              staticClass: "mb-3",
                                              attrs: {
                                                id: "hcfa-attachments-dropdown",
                                                "hide-selected": true,
                                                options: _vm.claimDocs,
                                                multiple: true,
                                                "show-labels": false,
                                                placeholder:
                                                  "-- Select an Option --",
                                                "track-by": "document_id",
                                                label: "filename",
                                              },
                                              model: {
                                                value: _vm.selectedDocs,
                                                callback: function ($$v) {
                                                  _vm.selectedDocs = $$v
                                                },
                                                expression: "selectedDocs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Print/Mail HCFA"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Already Mailed?"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.hcfa_mailed,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "hcfa_mailed",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.hcfa_mailed",
                                    },
                                  },
                                  [_vm._v("No")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.hcfa_mailed,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "hcfa_mailed",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.hcfa_mailed",
                                    },
                                  },
                                  [_vm._v("Yes")]
                                ),
                              ],
                              1
                            ),
                            _vm.worklog.hcfa_mailed == "0"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Location")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder:
                                              "Location of the HCFA's to be printed by staff",
                                          },
                                          model: {
                                            value: _vm.worklog.hcfa_location,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "hcfa_location",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.hcfa_location",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm.worklog.hcfa_mailed == "1"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "mailed-date" },
                                      },
                                      [_vm._v("Date Mailed")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            id: "mailed-date",
                                            placeholder: "",
                                            "value-as-datetime": "",
                                            "date-format-options": {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric",
                                            },
                                            "today-button": "",
                                            "reset-button": "",
                                            "close-button": "",
                                          },
                                          model: {
                                            value: _vm.worklog.mailed_date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "mailed_date",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.mailed_date",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "avo-accordian-container my-3" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    class: [
                                      _vm.docsAccordianVisible
                                        ? "avo-accordian-open-btn"
                                        : "avo-accordian-btn",
                                    ],
                                    attrs: {
                                      variant: "outline-primary",
                                      "aria-expanded": _vm.docsAccordianVisible
                                        ? "true"
                                        : "false",
                                      "aria-controls": "docs-collapse",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.docsAccordianVisible =
                                          !_vm.docsAccordianVisible
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("Attachments")]),
                                    !_vm.docsAccordianVisible
                                      ? _c("b-icon", {
                                          attrs: { icon: "paperclip" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "x", scale: "1.25" },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: { id: "docs-collapse" },
                                    model: {
                                      value: _vm.docsAccordianVisible,
                                      callback: function ($$v) {
                                        _vm.docsAccordianVisible = $$v
                                      },
                                      expression: "docsAccordianVisible",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      { staticClass: "docs-accordian" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column p-2",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "selecter-label" },
                                              [_vm._v("Upload new attachments")]
                                            ),
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("b-form-file", {
                                                  attrs: {
                                                    multiple: "",
                                                    placeholder:
                                                      "Select file(s) or drop them here...",
                                                    "drop-placeholder":
                                                      "Drop file here...",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "file-name",
                                                        fn: function (ref) {
                                                          var names = ref.names
                                                          return [
                                                            _c(
                                                              "b-badge",
                                                              {
                                                                staticClass:
                                                                  "document-badge",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    names[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            names.length > 1
                                                              ? _c(
                                                                  "b-badge",
                                                                  {
                                                                    staticClass:
                                                                      "document-badge ml-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " + " +
                                                                        _vm._s(
                                                                          names.length -
                                                                            1 ==
                                                                            1
                                                                            ? "1 more file"
                                                                            : names.length -
                                                                                1 +
                                                                                " more files"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2765966228
                                                  ),
                                                  model: {
                                                    value: _vm.files,
                                                    callback: function ($$v) {
                                                      _vm.files = $$v
                                                    },
                                                    expression: "files",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "selecter-label",
                                                attrs: {
                                                  for: "hcfa-attachments-dropdown",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Choose from existing attachments"
                                                ),
                                              ]
                                            ),
                                            _c("multiselect", {
                                              staticClass: "mb-3",
                                              attrs: {
                                                id: "hcfa-attachments-dropdown",
                                                "hide-selected": true,
                                                options: _vm.claimDocs,
                                                multiple: true,
                                                "show-labels": false,
                                                placeholder:
                                                  "-- Select an Option --",
                                                "track-by": "document_id",
                                                label: "filename",
                                              },
                                              model: {
                                                value: _vm.selectedDocs,
                                                callback: function ($$v) {
                                                  _vm.selectedDocs = $$v
                                                },
                                                expression: "selectedDocs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Corrected Patient Policies"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Rebilled?"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.rebilled,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.worklog, "rebilled", $$v)
                                      },
                                      expression: "worklog.rebilled",
                                    },
                                  },
                                  [_vm._v("No")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.rebilled,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.worklog, "rebilled", $$v)
                                      },
                                      expression: "worklog.rebilled",
                                    },
                                  },
                                  [_vm._v("Yes")]
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Secondary Insurance"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Select Payer"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("payer-multiselect", {
                                  attrs: {
                                    multiple: false,
                                    "allow-empty": false,
                                  },
                                  model: {
                                    value: _vm.multiselectVueModels.payer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.multiselectVueModels,
                                        "payer",
                                        $$v
                                      )
                                    },
                                    expression: "multiselectVueModels.payer",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Submittal Method"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Faxed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Mailed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "2", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Web Portal")]
                                ),
                              ],
                              1
                            ),
                            _vm.worklog.payer_contact_type == 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Fax Number")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_fax,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_fax",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_fax",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Mailing Address")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.worklog.payer_mailing_addr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_mailing_addr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.payer_mailing_addr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Portal")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_portal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_portal",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_portal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "mailed-date" },
                                      },
                                      [_vm._v("Date Sent")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            id: "mailed-date",
                                            placeholder: "",
                                            "value-as-datetime": "",
                                            "date-format-options": {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric",
                                            },
                                            "today-button": "",
                                            "reset-button": "",
                                            "close-button": "",
                                          },
                                          model: {
                                            value: _vm.worklog.mailed_date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "mailed_date",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.mailed_date",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center justify-content-center",
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label mr-2" },
                                      [_vm._v("EOB Retrieved:")]
                                    ),
                                    _c("b-form-checkbox", {
                                      staticClass: "selecter-label",
                                      model: {
                                        value: _vm.worklog.eob_retrieved,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog,
                                            "eob_retrieved",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.eob_retrieved",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Corrected Claim"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("ICN Number"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "Claim # of previous claim submission",
                                  },
                                  model: {
                                    value: _vm.worklog.icn_no,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "icn_no", $$v)
                                    },
                                    expression: "worklog.icn_no",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Issue"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { placeholder: "What was the issue?" },
                                  model: {
                                    value: _vm.worklog.issue_text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "issue_text", $$v)
                                    },
                                    expression: "worklog.issue_text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Resolution"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "What was the resolution?",
                                  },
                                  model: {
                                    value: _vm.worklog.resolution,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "resolution", $$v)
                                    },
                                    expression: "worklog.resolution",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Payment Pending"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Payer"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("payer-multiselect", {
                                  attrs: {
                                    multiple: false,
                                    "allow-empty": false,
                                  },
                                  model: {
                                    value: _vm.multiselectVueModels.payer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.multiselectVueModels,
                                        "payer",
                                        $$v
                                      )
                                    },
                                    expression: "multiselectVueModels.payer",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "selecter-label",
                                attrs: { for: "payer-addr" },
                              },
                              [_vm._v("Payer Address")]
                            ),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { id: "payer-addr" },
                                  model: {
                                    value: _vm.worklog.payer_mailing_addr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "payer_mailing_addr",
                                        $$v
                                      )
                                    },
                                    expression: "worklog.payer_mailing_addr",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Ready for Sending"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Action taken on claim"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.worklog.ready_for_sending_action,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "ready_for_sending_action",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "worklog.ready_for_sending_action",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Ready for Posting"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Payer"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("payer-multiselect", {
                                  attrs: {
                                    multiple: false,
                                    "allow-empty": false,
                                  },
                                  model: {
                                    value: _vm.multiselectVueModels.payer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.multiselectVueModels,
                                        "payer",
                                        $$v
                                      )
                                    },
                                    expression: "multiselectVueModels.payer",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "selecter-label",
                                attrs: { for: "payer-addr" },
                              },
                              [_vm._v("Payer Address")]
                            ),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { id: "payer-addr" },
                                  model: {
                                    value: _vm.worklog.payer_mailing_addr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "payer_mailing_addr",
                                        $$v
                                      )
                                    },
                                    expression: "worklog.payer_mailing_addr",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-amount" },
                                      },
                                      [_vm._v("Amount")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "payment-amount",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "amount"
                                        ),
                                      },
                                      model: {
                                        value: _vm.worklog.payment.amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "amount",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.payment.amount",
                                      },
                                    }),
                                    _c("b-form-invalid-feedback", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.fieldErrMessages(
                                            "payment",
                                            "amount"
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-date" },
                                      },
                                      [_vm._v("Payment Date")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "date",
                                        id: "payment-date",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "payment_date"
                                        ),
                                        placeholder: "",
                                        "value-as-datetime": "",
                                        "date-format-options": {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                        },
                                        "today-button": "",
                                        "reset-button": "",
                                        "close-button": "",
                                      },
                                      model: {
                                        value: _vm.worklog.payment.payment_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "payment_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "worklog.payment.payment_date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label",
                                        attrs: { for: "payment-invoice-no" },
                                      },
                                      [_vm._v("Check/EFT No.")]
                                    ),
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "payment-invoice-no",
                                        state: _vm.fieldStates(
                                          "payment",
                                          "invoice_no"
                                        ),
                                      },
                                      model: {
                                        value: _vm.worklog.payment.invoice_no,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog.payment,
                                            "invoice_no",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "worklog.payment.invoice_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("EOB Type")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "b-form-radio",
                                          {
                                            attrs: { value: "0", inline: "" },
                                            model: {
                                              value: _vm.worklog.eob_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.worklog,
                                                  "eob_type",
                                                  $$v
                                                )
                                              },
                                              expression: "worklog.eob_type",
                                            },
                                          },
                                          [_vm._v("ERA")]
                                        ),
                                        _c(
                                          "b-form-radio",
                                          {
                                            attrs: { value: "1", inline: "" },
                                            model: {
                                              value: _vm.worklog.eob_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.worklog,
                                                  "eob_type",
                                                  $$v
                                                )
                                              },
                                              expression: "worklog.eob_type",
                                            },
                                          },
                                          [_vm._v("Paper")]
                                        ),
                                        _c(
                                          "b-form-radio",
                                          {
                                            attrs: { value: "2", inline: "" },
                                            model: {
                                              value: _vm.worklog.eob_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.worklog,
                                                  "eob_type",
                                                  $$v
                                                )
                                              },
                                              expression: "worklog.eob_type",
                                            },
                                          },
                                          [_vm._v("Portal")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center pl-0",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "selecter-label mr-2",
                                        attrs: { for: "mailed-date" },
                                      },
                                      [_vm._v("Notified Poster:")]
                                    ),
                                    _c("b-form-checkbox", {
                                      staticClass: "selecter-label",
                                      model: {
                                        value: _vm.worklog.notified_poster,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog,
                                            "notified_poster",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.notified_poster",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Clearinghouse Error/Rejected"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Clearinghouse Name"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.worklog.clearinghouse_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "clearinghouse_name",
                                        $$v
                                      )
                                    },
                                    expression: "worklog.clearinghouse_name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Error Type"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.optionsMapItem(
                                      "clearinghouse_error_types"
                                    ),
                                    required: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: null,
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "-- Select an error type --"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3109708447
                                  ),
                                  model: {
                                    value: _vm.worklog.clearinghouse_error_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "clearinghouse_error_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "worklog.clearinghouse_error_type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Issue"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { placeholder: "What was the issue" },
                                  model: {
                                    value: _vm.worklog.issue_text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "issue_text", $$v)
                                    },
                                    expression: "worklog.issue_text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Resolution"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "What is the resolution?",
                                  },
                                  model: {
                                    value: _vm.worklog.resolution,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "resolution", $$v)
                                    },
                                    expression: "worklog.resolution",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Pending Adjustments/Write-Off"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Adjustment Type"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options:
                                      _vm.optionsMapItem("adjustment_types"),
                                    required: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: null,
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "-- Select an adjustment type --"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2040335784
                                  ),
                                  model: {
                                    value: _vm.worklog.adjustment_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "adjustment_type",
                                        $$v
                                      )
                                    },
                                    expression: "worklog.adjustment_type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Amount")]
                                    ),
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.worklog.adjustment_amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog,
                                            "adjustment_amount",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.adjustment_amount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Adjustment Date")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            placeholder: "",
                                            "value-as-datetime": "",
                                            "date-format-options": {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric",
                                            },
                                            "today-button": "",
                                            "reset-button": "",
                                            "close-button": "",
                                          },
                                          model: {
                                            value: _vm.worklog.adjustment_date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "adjustment_date",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.adjustment_date",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Reviewed/Approved By"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("worker-multiselect", {
                                  attrs: {
                                    "v-model": _vm.approvedWorker,
                                    placeholder: "-- Select a worker --",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Reviewed Denial"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Denial Type"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    state: _vm.fieldStates(
                                      "denial",
                                      "denial_type"
                                    ),
                                    options: _vm.optionsMapItem("denial_types"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: null,
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "-- Select a denial type --"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4117748162
                                  ),
                                  model: {
                                    value: _vm.worklog.denial_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "denial_type", $$v)
                                    },
                                    expression: "worklog.denial_type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Issue"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { placeholder: "What was the issue?" },
                                  model: {
                                    value: _vm.worklog.issue_text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "issue_text", $$v)
                                    },
                                    expression: "worklog.issue_text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Resolution"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "What was the resolution?",
                                  },
                                  model: {
                                    value: _vm.worklog.resolution,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "resolution", $$v)
                                    },
                                    expression: "worklog.resolution",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Claim Not On File"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Verified"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "Where was this verified?",
                                  },
                                  model: {
                                    value: _vm.worklog.verified_where,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "verified_where",
                                        $$v
                                      )
                                    },
                                    expression: "worklog.verified_where",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v(
                                "Is there a log of the claim in the clearinghouse?"
                              ),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.log_in_clearinghouse,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "log_in_clearinghouse",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "worklog.log_in_clearinghouse",
                                    },
                                  },
                                  [_vm._v("No")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.log_in_clearinghouse,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "log_in_clearinghouse",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "worklog.log_in_clearinghouse",
                                    },
                                  },
                                  [_vm._v("Yes")]
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Resubmitted"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "0", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Faxed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "1", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Mailed")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    attrs: { value: "2", inline: "" },
                                    model: {
                                      value: _vm.worklog.payer_contact_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.worklog,
                                          "payer_contact_type",
                                          $$v
                                        )
                                      },
                                      expression: "worklog.payer_contact_type",
                                    },
                                  },
                                  [_vm._v("Web Portal")]
                                ),
                              ],
                              1
                            ),
                            _vm.worklog.payer_contact_type == 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Fax Number")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_fax,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_fax",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_fax",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Mailing Address")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.worklog.payer_mailing_addr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_mailing_addr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.payer_mailing_addr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Portal")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value: _vm.worklog.payer_portal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "payer_portal",
                                                $$v
                                              )
                                            },
                                            expression: "worklog.payer_portal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.worklog.payer_contact_type
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Confirmation Number")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.worklog
                                                .resubmittal_confirmation_no,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "resubmittal_confirmation_no",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.resubmittal_confirmation_no",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "In Dispute"
                      ? _c(
                          "div",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Date of last claim submission")]
                                    ),
                                    _c(
                                      "b-form-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "date",
                                            placeholder: "",
                                            "value-as-datetime": "",
                                            "date-format-options": {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric",
                                            },
                                            "today-button": "",
                                            "reset-button": "",
                                            "close-button": "",
                                          },
                                          model: {
                                            value:
                                              _vm.worklog.last_submitted_date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.worklog,
                                                "last_submitted_date",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "worklog.last_submitted_date",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "selecter-label" },
                                      [_vm._v("Reference Number")]
                                    ),
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.worklog.reference_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.worklog,
                                            "reference_number",
                                            $$v
                                          )
                                        },
                                        expression: "worklog.reference_number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Issue"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { placeholder: "What was the issue?" },
                                  model: {
                                    value: _vm.worklog.issue_text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "issue_text", $$v)
                                    },
                                    expression: "worklog.issue_text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Coding"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Coding Error"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options:
                                      _vm.optionsMapItem("coding_errors"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: null,
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "-- Select a coding error --"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3535814817
                                  ),
                                  model: {
                                    value: _vm.worklog.coding_error,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "coding_error", $$v)
                                    },
                                    expression: "worklog.coding_error",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "selecter-label",
                                attrs: { for: "cpt-code-dropdown" },
                              },
                              [_vm._v("CPT Codes Changed")]
                            ),
                            _c("multiselect", {
                              staticClass: "mb-3",
                              attrs: {
                                id: "cpt-code-dropdown",
                                "hide-selected": true,
                                options: _vm.claimCPTCodes,
                                "custom-label": _vm.customCPTCodeLabel,
                                multiple: true,
                                "show-labels": false,
                                placeholder: "-- Select an Option --",
                              },
                              model: {
                                value: _vm.worklog.cpt_codes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.worklog, "cpt_codes", $$v)
                                },
                                expression: "worklog.cpt_codes",
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "selecter-label",
                                attrs: { for: "icd-code-dropdown" },
                              },
                              [_vm._v("ICD Codes Changed")]
                            ),
                            _c("multiselect", {
                              staticClass: "mb-3",
                              attrs: {
                                id: "icd-code-dropdown",
                                "hide-selected": true,
                                options: _vm.claimICDCodes,
                                "custom-label": function (code) {
                                  return code.code
                                },
                                multiple: true,
                                "show-labels": false,
                                placeholder: "-- Select an Option --",
                              },
                              model: {
                                value: _vm.worklog.icd_codes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.worklog, "icd_codes", $$v)
                                },
                                expression: "worklog.icd_codes",
                              },
                            }),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Codes updated by"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("worker-multiselect", {
                                  attrs: {
                                    "v-model": _vm.codesWorker,
                                    placeholder: "-- Select a worker --",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.fieldStates("codes", "has_codes") === false
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "avo-invalid-feedback",
                                    staticStyle: { "margin-top": "-10px" },
                                  },
                                  [_vm._v("Please select the updated code(s)")]
                                )
                              : _vm._e(),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Reason"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    placeholder:
                                      "Please specify a reason for the change",
                                    state: _vm.fieldStates(
                                      "codes",
                                      "description"
                                    ),
                                  },
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Resolved - Paid" ||
                    _vm.workTypeName == "Resolved - Other"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Insurance Reprocessing"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Payer"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("payer-multiselect", {
                                  attrs: {
                                    multiple: false,
                                    "allow-empty": false,
                                  },
                                  model: {
                                    value: _vm.multiselectVueModels.payer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.multiselectVueModels,
                                        "payer",
                                        $$v
                                      )
                                    },
                                    expression: "multiselectVueModels.payer",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "selecter-label",
                                attrs: { for: "payer-addr" },
                              },
                              [_vm._v("Payer Address")]
                            ),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { id: "payer-addr" },
                                  model: {
                                    value: _vm.worklog.payer_mailing_addr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "payer_mailing_addr",
                                        $$v
                                      )
                                    },
                                    expression: "worklog.payer_mailing_addr",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Reference Number"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.worklog.reference_number,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.worklog,
                                        "reference_number",
                                        $$v
                                      )
                                    },
                                    expression: "worklog.reference_number",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Issue"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: { placeholder: "What was the issue?" },
                                  model: {
                                    value: _vm.worklog.issue_text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "issue_text", $$v)
                                    },
                                    expression: "worklog.issue_text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Resolution"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "What was the resolution?",
                                  },
                                  model: {
                                    value: _vm.worklog.resolution,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "resolution", $$v)
                                    },
                                    expression: "worklog.resolution",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.workTypeName == "Other"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Notes"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-textarea", {
                                  model: {
                                    value: _vm.worklog.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.worklog, "description", $$v)
                                    },
                                    expression: "worklog.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.worklog.work_type
                      ? _c(
                          "div",
                          { staticClass: "avo-accordian-container my-4" },
                          [
                            _c(
                              "b-button",
                              {
                                class: [
                                  _vm.taskVisible
                                    ? "avo-accordian-open-btn"
                                    : "avo-accordian-btn",
                                ],
                                attrs: {
                                  variant: "outline-primary",
                                  "aria-expanded": _vm.taskVisible
                                    ? "true"
                                    : "false",
                                  "aria-controls": "task-collapse",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.taskVisible = !_vm.taskVisible
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v("Create Next Follow Up Task"),
                                ]),
                                !_vm.taskVisible
                                  ? _c("b-icon", {
                                      attrs: { icon: "check2-circle" },
                                    })
                                  : _c("b-icon", {
                                      attrs: { icon: "x", scale: "1.25" },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "b-collapse",
                              {
                                attrs: { id: "task-collapse" },
                                model: {
                                  value: _vm.taskVisible,
                                  callback: function ($$v) {
                                    _vm.taskVisible = $$v
                                  },
                                  expression: "taskVisible",
                                },
                              },
                              [
                                _c(
                                  "b-card",
                                  { staticClass: "task-accordian" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column p-2" },
                                      [
                                        _vm.hasPermission("assign_claims")
                                          ? _c(
                                              "label",
                                              {
                                                staticClass: "selecter-label",
                                                attrs: { for: "task-worker" },
                                              },
                                              [_vm._v("Assigned")]
                                            )
                                          : _vm._e(),
                                        _vm.hasPermission("assign_claims")
                                          ? _c(
                                              "b-form-group",
                                              { attrs: { id: "task-worker" } },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("worker-multiselect", {
                                                      attrs: {
                                                        placeholder:
                                                          "Select a worker or leave empty to assign self",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.taskAssignedWorker,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.taskAssignedWorker =
                                                            $$v
                                                        },
                                                        expression:
                                                          "taskAssignedWorker",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "label",
                                          { staticClass: "selecter-label" },
                                          [_vm._v("Title")]
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                state:
                                                  _vm.taskFieldStates("title"),
                                              },
                                              model: {
                                                value: _vm.worklog.task.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.worklog.task,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "worklog.task.title",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "selecter-label",
                                                  },
                                                  [_vm._v("Due date")]
                                                ),
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "date",
                                                        placeholder:
                                                          "Choose a date",
                                                        state:
                                                          _vm.taskFieldStates(
                                                            "taskDueDate"
                                                          ),
                                                        "date-format-options": {
                                                          year: "numeric",
                                                          month: "numeric",
                                                          day: "numeric",
                                                        },
                                                        "today-button": "",
                                                        "reset-button": "",
                                                        "close-button": "",
                                                      },
                                                      model: {
                                                        value: _vm.taskDueDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.taskDueDate = $$v
                                                        },
                                                        expression:
                                                          "taskDueDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "selecter-label",
                                                  },
                                                  [_vm._v("Due time")]
                                                ),
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "time",
                                                        placeholder:
                                                          "Choose a time",
                                                        state:
                                                          _vm.taskFieldStates(
                                                            "taskDueTime"
                                                          ),
                                                        locale: "en",
                                                      },
                                                      model: {
                                                        value: _vm.taskDueTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.taskDueTime = $$v
                                                        },
                                                        expression:
                                                          "taskDueTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "label",
                                          { staticClass: "selecter-label" },
                                          [_vm._v("Description")]
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("b-form-textarea", {
                                              model: {
                                                value: _vm.worklog.task.body,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.worklog.task,
                                                    "body",
                                                    $$v
                                                  )
                                                },
                                                expression: "worklog.task.body",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.claimSubstatusOptions &&
                    _vm.worklog.work_type &&
                    _vm.workTypeName != "Corrected Patient Policies" &&
                    _vm.workTypeName != "Resolved - Other" &&
                    _vm.workTypeName != "Resolved - Paid"
                      ? _c(
                          "div",
                          [
                            _c("label", { staticClass: "selecter-label" }, [
                              _vm._v("Add a Claim Sub-status"),
                            ]),
                            _c(
                              "b-form-group",
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.claimSubstatusOptions,
                                    required: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              {
                                                attrs: {
                                                  value: null,
                                                  disabled: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "-- Select a Substatus --"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1638094785
                                  ),
                                  model: {
                                    value: _vm.substatus,
                                    callback: function ($$v) {
                                      _vm.substatus = $$v
                                    },
                                    expression: "substatus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "avo-text-light" }, [
                          _vm._v(
                            " No claim substatuses available for this transition "
                          ),
                        ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _vm.issues
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mr-2 mb-0",
                                    staticStyle: {
                                      "font-weight": "500",
                                      "font-size": "14px",
                                      color: "#C84C42",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Send this claim back to the Claims Dash?"
                                    ),
                                  ]
                                ),
                                _c("b-form-checkbox", {
                                  model: {
                                    value: _vm.send_to_main,
                                    callback: function ($$v) {
                                      _vm.send_to_main = $$v
                                    },
                                    expression: "send_to_main",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mr-2 mb-0",
                                    staticStyle: {
                                      "font-weight": "500",
                                      "font-size": "14px",
                                      color: "#C84C42",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Send this claim to the Issues Dash?"
                                    ),
                                  ]
                                ),
                                _c("b-form-checkbox", {
                                  model: {
                                    value: _vm.send_to_issues,
                                    callback: function ($$v) {
                                      _vm.send_to_issues = $$v
                                    },
                                    expression: "send_to_issues",
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "b-button",
                          {
                            staticClass: "avo-primary-btn",
                            attrs: {
                              disabled: _vm.submitInProgress,
                              variant: "outline-primary",
                            },
                            on: { click: _vm.onSubmit },
                          },
                          [
                            _vm.taskVisible
                              ? _c("span", [_vm._v("Log Work and Create Task")])
                              : _c("span", [_vm._v("Log Work")]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }