var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.getModalID,
        title: "Edit Task",
        "hide-footer": "",
        size: "md",
      },
    },
    [
      _c(
        "div",
        { staticClass: "p-3" },
        [
          _vm.claim != null
            ? _c(
                "b-card",
                { staticClass: "claim-preview-card mb-3" },
                [
                  _c(
                    "b-row",
                    {},
                    [
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("CLAIM ID"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.claim_id)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("PAYER"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.payer_name)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("STATUS"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("div", {
                                class: ["status-icon", _vm.claim.state.short],
                              }),
                              _c("div", { staticClass: "data-text" }, [
                                _vm._v(_vm._s(_vm.claim.state.label) + " "),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("hr"),
                  _c(
                    "b-row",
                    {},
                    [
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("PATIENT"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.patient_name)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("PROVIDER"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.provider_name)),
                          ]),
                        ]),
                      ]),
                      _vm.issues
                        ? _c("b-col", { attrs: { cols: "4" } }, [
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("div", { staticClass: "label-text" }, [
                                _vm._v("ISSUE"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "claim-flag" },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "exclamation-circle" },
                                  }),
                                  _vm._v(_vm._s(_vm.claim.flag)),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c("avo-alert", {
                attrs: { avoAlerts: _vm.avoAlerts },
                on: {
                  "update:avoAlerts": function ($event) {
                    _vm.avoAlerts = $event
                  },
                  "update:avo-alerts": function ($event) {
                    _vm.avoAlerts = $event
                  },
                },
              }),
              _c(
                "label",
                { staticClass: "selecter-label", attrs: { for: "task-title" } },
                [_vm._v("Title")]
              ),
              _c(
                "b-form-group",
                { attrs: { id: "task-title" } },
                [
                  _c("b-form-input", {
                    model: {
                      value: _vm.taskForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskForm, "title", $$v)
                      },
                      expression: "taskForm.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                { staticClass: "selecter-label", attrs: { for: "task-body" } },
                [_vm._v("Body")]
              ),
              _c(
                "b-form-group",
                { attrs: { id: "task-body" } },
                [
                  _c("b-form-textarea", {
                    model: {
                      value: _vm.taskForm.body,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskForm, "body", $$v)
                      },
                      expression: "taskForm.body",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-danger-btn mr-2",
                      on: { click: _vm.deleteTask },
                    },
                    [_vm._v(" Delete Task ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v(" Save Task ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }