var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      !_vm.hideSearchGroup
        ? _c(
            "b-row",
            {
              staticClass: "px-5 pt-3 sticky-top",
              staticStyle: { background: "white", "z-index": "99" },
            },
            [
              _c(
                "b-col",
                { attrs: { sm: "auto" } },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "start" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "auto" } },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "search-group" },
                            [
                              _c("b-input-group-prepend", [
                                _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [_c("b-icon", { attrs: { icon: "search" } })],
                                  1
                                ),
                              ]),
                              _c("b-form-input", {
                                staticClass: "worklog-search-input",
                                attrs: {
                                  type: "search",
                                  placeholder: "Search",
                                },
                                on: {
                                  keyup: _vm.debounceLogs,
                                  search: _vm.debounceLogs,
                                },
                                model: {
                                  value: _vm.filter.terms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "terms", $$v)
                                  },
                                  expression: "filter.terms",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "auto" } },
                        [
                          _c("multiselect", {
                            staticStyle: { "min-width": "240px" },
                            attrs: {
                              "show-labels": false,
                              options: _vm.workTypeOptionsList.map(function (
                                i
                              ) {
                                return i.id
                              }),
                              "custom-label": function (opt) {
                                return _vm.workTypeOptionsList.find(function (
                                  x
                                ) {
                                  return x.id == opt
                                }).name
                              },
                              "hide-selected": false,
                              "close-on-select": false,
                              multiple: true,
                              "internal-search": false,
                              searchable: false,
                              placeholder: "By Work Type",
                            },
                            model: {
                              value: _vm.filter.types,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "types", $$v)
                              },
                              expression: "filter.types",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "auto" } },
                        [
                          _vm.workerId == null
                            ? _c("multiselect", {
                                staticStyle: { "min-width": "200px" },
                                attrs: {
                                  "show-labels": false,
                                  options: _vm.workerOptionsList.map(function (
                                    i
                                  ) {
                                    return i.worker_id
                                  }),
                                  "custom-label": function (opt) {
                                    return _vm.workerOptionsList.find(function (
                                      x
                                    ) {
                                      return x.worker_id == opt
                                    }).worker_name
                                  },
                                  "hide-selected": false,
                                  "close-on-select": false,
                                  multiple: true,
                                  "internal-search": false,
                                  searchable: false,
                                  placeholder: "By Worker",
                                },
                                model: {
                                  value: _vm.filter.workers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "workers", $$v)
                                  },
                                  expression: "filter.workers",
                                },
                              })
                            : _c("multiselect", {
                                staticClass: "mr-2",
                                staticStyle: { "min-width": "200px" },
                                attrs: {
                                  "show-labels": false,
                                  options: _vm.worker_claims,
                                  "hide-selected": false,
                                  "close-on-select": false,
                                  multiple: true,
                                  "internal-search": false,
                                  searchable: false,
                                  placeholder: "By Claim ID",
                                },
                                model: {
                                  value: _vm.filter.claims,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "claims", $$v)
                                  },
                                  expression: "filter.claims",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "" } }),
              !_vm.hideLogWorkButton
                ? _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { sm: "auto" } },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.new-worklog",
                              modifiers: { "new-worklog": true },
                            },
                          ],
                          staticClass: "avo-primary-btn",
                          attrs: { variant: "outline-primary" },
                        },
                        [_vm._v(" Log Work ")]
                      ),
                      _c("work-log-add", {
                        attrs: { claim: _vm.claim, issues: _vm.issues },
                        on: {
                          logAdded: function ($event) {
                            return _vm.getLogs()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              class: [_vm.embedded ? "pl-2" : "pl-5", "ml-4", "mt-4"],
              attrs: { xl: _vm.hideSearchGroup ? "11" : "10" },
            },
            _vm._l(_vm.worklogs, function (worklog, index) {
              return _c("div", { key: worklog.worklog_id }, [
                _c("div", { staticClass: "log-entry-container" }, [
                  _c("div", { staticClass: "circle-icon" }),
                  _c("div", { staticClass: "log-date" }, [
                    _vm._v(" " + _vm._s(_vm.formatDate(worklog.created)) + " "),
                    _c("span", { staticClass: "avo-text-light-small" }, [
                      _vm._v(_vm._s(_vm.formatTime(worklog.created))),
                    ]),
                  ]),
                  !_vm.clicked[index]
                    ? _c(
                        "div",
                        {
                          staticClass: "worklog-card",
                          on: {
                            click: function ($event) {
                              return _vm.selectLog(index)
                            },
                          },
                        },
                        [
                          _c("div", [
                            _vm.showClaimId && worklog.claim_id
                              ? _c(
                                  "span",
                                  { staticStyle: { "font-weight": "500" } },
                                  [
                                    _vm._v(
                                      "Claim #" +
                                        _vm._s(worklog.claim_id) +
                                        " - "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(
                              _vm._s(
                                worklog.work_type == "-999"
                                  ? worklog.title
                                  : worklog.worktype_label
                              ) + " "
                            ),
                          ]),
                          !(worklog.work_type == "-999")
                            ? _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "avo-text-light-small mr-1" },
                                  [_vm._v("Logged by")]
                                ),
                                _vm._v(" " + _vm._s(worklog.worker_name) + " "),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _c("div", { staticClass: "worklog-clicked shadow" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "\n                d-flex\n                justify-content-between\n                align-items-center\n                cursor-pointer\n                mb-2\n              ",
                            on: {
                              click: function ($event) {
                                return _vm.selectLog(index)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column pt-3" },
                              [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "16px" } },
                                    [
                                      _vm.showClaimId && worklog.claim_id
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Claim #" +
                                                  _vm._s(worklog.claim_id) +
                                                  " - "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(
                                          worklog.work_type == "-999"
                                            ? worklog.title
                                            : worklog.worktype_label
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            !(worklog.work_type == -999)
                              ? _c("div", { staticClass: "mt-3" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "avo-text-light-small mr-2",
                                    },
                                    [_vm._v("Logged by")]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "16px" } },
                                    [_vm._v(_vm._s(worklog.worker_name))]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        worklog.work_type == "-999"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          {
                                            staticClass: "log-data-row mb-0",
                                            staticStyle: { border: "none" },
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "2" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        worklog.description
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "0"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        worklog.submittal_type == 0
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Submitted to Payer"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payer_name
                                                                ? worklog.payer_name
                                                                : "N/A"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        worklog.submittal_type == 0
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Payer Address")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payer_mailing_addr
                                                                ? worklog.payer_mailing_addr
                                                                : "N/A"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        worklog.submittal_type == 1
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Submitted to Clearinghouse"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.clearinghouse_name
                                                                ? worklog.clearinghouse_name
                                                                : "N/A"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "10"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_name
                                                            ? worklog.payer_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer Phone")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_phone
                                                            ? worklog.payer_phone
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Rep Name")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.rep_name
                                                            ? worklog.rep_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Reference Number")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.reference_number
                                                            ? worklog.reference_number
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Issue")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.issue_text
                                                            ? worklog.issue_text
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Resolution")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.resolution
                                                            ? worklog.resolution
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "20"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Received By")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_contact_type
                                                            ? _vm.optionsMap
                                                                .payer_contact_types[
                                                                worklog
                                                                  .payer_contact_type
                                                              ]
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Amount")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment.amount
                                                            ? "$" +
                                                                worklog.payment.amount.toLocaleString(
                                                                  "en-US",
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payment Date")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment
                                                            .payment_date
                                                            ? _vm.formatDate(
                                                                worklog.payment
                                                                  .payment_date
                                                              )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Check/ETF Number")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment
                                                            .invoice_no
                                                            ? worklog.payment
                                                                .invoice_no
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payment Status")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.posted
                                                            ? "Posted"
                                                            : "Not yet posted"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Attachments")]
                                                ),
                                              ]
                                            ),
                                            worklog.documents &&
                                            worklog.documents.length > 0
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "d-flex pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  _vm._l(
                                                    worklog.documents,
                                                    function (document) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: document.document_id,
                                                          staticClass:
                                                            "filename-link mr-2",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                role: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getDownloadUrl(
                                                                      document
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  document.filename
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [_vm._v("N/A")]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "30"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Type")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                worklog.payment
                                                  .payment_source == "1"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payment
                                                                .patient_payment_type
                                                                ? _vm.optionsMap
                                                                    .patient_payment_types[
                                                                    worklog
                                                                      .payment
                                                                      .patient_payment_type
                                                                  ]
                                                                : "N/A"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Method")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                worklog.payment
                                                  .payment_source == "1"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payment
                                                                .patient_payment_method
                                                                ? _vm.optionsMap
                                                                    .patient_payment_methods[
                                                                    worklog
                                                                      .payment
                                                                      .patient_payment_method
                                                                  ]
                                                                : "N/A"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Amount")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment.amount
                                                            ? "$" +
                                                                worklog.payment.amount.toLocaleString(
                                                                  "en-US",
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payment Date")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment
                                                            .payment_date
                                                            ? _vm.formatDate(
                                                                worklog.payment
                                                                  .payment_date
                                                              )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Check/ETF Number")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment
                                                            .invoice_no
                                                            ? worklog.payment
                                                                .invoice_no
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payment Status")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.posted
                                                            ? "Posted"
                                                            : "Not yet posted"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "40"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_name
                                                            ? worklog.payer_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.payer_contact_type
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Method")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "500",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.optionsMap
                                                                  .payer_contact_types[
                                                                  worklog
                                                                    .payer_contact_type
                                                                ]
                                                              ) + ": "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payer_contact_type ==
                                                                0
                                                                ? worklog.payer_fax
                                                                : worklog.payer_contact_type ==
                                                                  1
                                                                ? worklog.payer_mailing_addr
                                                                : worklog.payer_portal
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Attachments")]
                                                ),
                                              ]
                                            ),
                                            worklog.documents &&
                                            worklog.documents.length > 0
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "d-flex pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  _vm._l(
                                                    worklog.documents,
                                                    function (document) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: document.document_id,
                                                          staticClass:
                                                            "filename-link mr-2",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                role: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getDownloadUrl(
                                                                      document
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  document.filename
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [_vm._v("N/A")]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "50"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_name
                                                            ? worklog.payer_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.payer_contact_type
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Submittal Method"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "500",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.optionsMap
                                                                  .payer_contact_types[
                                                                  worklog
                                                                    .payer_contact_type
                                                                ]
                                                              ) + ": "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payer_contact_type ==
                                                                0
                                                                ? worklog.payer_fax
                                                                : worklog.payer_contact_type ==
                                                                  1
                                                                ? worklog.payer_mailing_addr
                                                                : worklog.payer_portal
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Issue")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.issue_text
                                                            ? worklog.issue_text
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Resolution")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.resolution
                                                            ? worklog.resolution
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Attachments")]
                                                ),
                                              ]
                                            ),
                                            worklog.documents &&
                                            worklog.documents.length > 0
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "d-flex pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  _vm._l(
                                                    worklog.documents,
                                                    function (document) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: document.document_id,
                                                          staticClass:
                                                            "filename-link mr-2",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                role: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getDownloadUrl(
                                                                      document
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  document.filename
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [_vm._v("N/A")]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "70"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Rebilled")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.rebilled
                                                            ? "Yes"
                                                            : "No"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "60"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Already Mailed")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.hcfa_mailed
                                                            ? "Yes"
                                                            : "No"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.hcfa_mailed
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Date mailed")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.mailed_date
                                                                ? _vm.formatDate(
                                                                    worklog.mailed_date
                                                                  )
                                                                : "N/A"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Location")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.hcfa_location
                                                                ? worklog.hcfa_location
                                                                : "N/A"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Attachments")]
                                                ),
                                              ]
                                            ),
                                            worklog.documents &&
                                            worklog.documents.length > 0
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "d-flex pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  _vm._l(
                                                    worklog.documents,
                                                    function (document) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: document.document_id,
                                                          staticClass:
                                                            "filename-link mr-2",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                role: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getDownloadUrl(
                                                                      document
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  document.filename
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [_vm._v("N/A")]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "80"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_name
                                                            ? worklog.payer_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.payer_contact_type
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Submittal Method"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "500",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.optionsMap
                                                                  .payer_contact_types[
                                                                  worklog
                                                                    .payer_contact_type
                                                                ]
                                                              ) + ": "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payer_contact_type ==
                                                                0
                                                                ? worklog.payer_fax
                                                                : worklog.payer_contact_type ==
                                                                  1
                                                                ? worklog.payer_mailing_addr
                                                                : worklog.payer_portal
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Date Sent")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.mailed_date
                                                            ? _vm.formatDate(
                                                                worklog.mailed_date
                                                              )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("EOB Retrieved?")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.eob_retrieved
                                                            ? "Yes"
                                                            : "No"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "90"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("ICN Number")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.icn_no
                                                            ? worklog.icn_no
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Issue")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.issue_text
                                                            ? worklog.issue_text
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Resolution")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.resolution
                                                            ? worklog.resolution
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "100"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_name
                                                            ? worklog.payer_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer Address")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_mailing_addr
                                                            ? worklog.payer_mailing_addr
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "110"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Action Taken")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.ready_for_sending_action
                                                            ? worklog.ready_for_sending_action
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "120"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_name
                                                            ? worklog.payer_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer Address")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_mailing_addr
                                                            ? worklog.payer_mailing_addr
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Amount")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment.amount
                                                            ? "$" +
                                                                worklog.payment.amount.toLocaleString(
                                                                  "en-US",
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payment Date")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment
                                                            .payment_date
                                                            ? _vm.formatDate(
                                                                worklog.payment
                                                                  .payment_date
                                                              )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Check/ETF Number")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payment
                                                            .invoice_no
                                                            ? worklog.payment
                                                                .invoice_no
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("EOB Type")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.eob_type
                                                            ? _vm.optionsMap
                                                                .eob_types[
                                                                worklog.eob_type
                                                              ]
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notified Poster?")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.notified_poster
                                                            ? "Yes"
                                                            : "No"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "130"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Clearinghouse Name")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.clearinghouse_name
                                                            ? worklog.clearinghouse_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Error Type")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.clearinghouse_error_type
                                                            ? _vm.optionsMap
                                                                .clearinghouse_error_types[
                                                                worklog
                                                                  .clearinghouse_error_type
                                                              ]
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Issue")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.issue_text
                                                            ? worklog.issue_text
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Resolution")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.resolution
                                                            ? worklog.resolution
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "140"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Adjustment Type")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.adjustment_type
                                                            ? _vm.optionsMap
                                                                .adjustment_types[
                                                                worklog
                                                                  .adjustment_type
                                                              ]
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Adjustment Date")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.adjustment_date
                                                            ? _vm.formatDate(
                                                                worklog.adjustment_date
                                                              )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Amount")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.adjustment_amount
                                                            ? "$" +
                                                                worklog.adjustment_amount.toLocaleString(
                                                                  "en-US",
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Reviewed/Approved By"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.approved_by_name
                                                            ? worklog.approved_by_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "150"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Denial Type")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.denial_type
                                                            ? _vm.optionsMap
                                                                .denial_types[
                                                                worklog
                                                                  .denial_type
                                                              ]
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Issue")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.issue_text
                                                            ? worklog.issue_text
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Resolution")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.resolution
                                                            ? worklog.resolution
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "160"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Verified")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.verified_where
                                                            ? worklog.verified_where
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.payer_contact_type
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Resubmittal Method"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "500",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.optionsMap
                                                                  .payer_contact_types[
                                                                  worklog
                                                                    .payer_contact_type
                                                                ]
                                                              ) + ": "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              worklog.payer_contact_type ==
                                                                0
                                                                ? worklog.payer_fax
                                                                : worklog.payer_contact_type ==
                                                                  1
                                                                ? worklog.payer_mailing_addr
                                                                : worklog.payer_portal
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Confirmation Number"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.resubmittal_confirmation_no
                                                            ? worklog.resubmittal_confirmation_no
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Claim Logged in Clearinghouse?"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.log_in_clearinghouse
                                                            ? "Yes"
                                                            : "No"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "170"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Last Submission Date"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.last_submitted_date
                                                            ? _vm.formatDate(
                                                                worklog.last_submitted_date
                                                              )
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Reference Number")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.reference_number
                                                            ? worklog.reference_number
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Issue")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.issue_text
                                                            ? worklog.issue_text
                                                            : "No"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "180"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Coding Error")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.coding_error
                                                            ? _vm.optionsMap
                                                                .coding_errors[
                                                                worklog
                                                                  .coding_error
                                                              ]
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.cpt_codes.length > 0
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Updated CPT Codes"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  _vm._l(
                                                    worklog.cpt_codes,
                                                    function (code) {
                                                      return _c(
                                                        "b-row",
                                                        { key: code.code },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    code.code
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "avo-text-light",
                                                              attrs: {
                                                                cols: "10",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    code.long
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        worklog.icd_codes.length > 0
                                          ? _c(
                                              "b-row",
                                              { staticClass: "log-data-row" },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Updated ICD Codes"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  _vm._l(
                                                    worklog.icd_codes,
                                                    function (code) {
                                                      return _c(
                                                        "b-row",
                                                        { key: code.code },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    code.code
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "avo-text-light",
                                                              attrs: {
                                                                cols: "10",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    code.description
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Coding Updated By")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        worklog.codes_changed_by
                                                          ? worklog.codes_changed_by
                                                          : "N/A"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Reason")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        worklog.description
                                                          ? worklog.description
                                                          : "N/A"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "190"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "200"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "210"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_name
                                                            ? worklog.payer_name
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Payer Address")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.payer_mailing_addr
                                                            ? worklog.payer_mailing_addr
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Reference Number")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.reference_number
                                                            ? worklog.reference_number
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Issue")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.issue_text
                                                            ? worklog.issue_text
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Resolution")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.resolution
                                                            ? worklog.resolution
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "log-data-row" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        worklog.work_type == "900"
                          ? _c(
                              "div",
                              { staticClass: "p-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "pl-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c(
                                          "b-row",
                                          {
                                            staticClass: "log-data-row mb-0",
                                            staticStyle: { border: "none" },
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "avo-text-bold",
                                                  },
                                                  [_vm._v("Notes")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pl-0",
                                                attrs: { cols: "9" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          worklog.description
                                                            ? worklog.description
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        worklog.task
                                          ? _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "log-data-row mb-0",
                                                staticStyle: { border: "none" },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-bold",
                                                      },
                                                      [_vm._v("Next Followup")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "pl-0",
                                                    attrs: { cols: "9" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                        staticStyle: {
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          '"' +
                                                            _vm._s(
                                                              worklog.task.title
                                                            ) +
                                                            '"'
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "avo-text-light",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " due " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            ) +
                                                            " at " +
                                                            _vm._s(
                                                              _vm.formatTime(
                                                                worklog.task
                                                                  .due_date
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "avo-text-light",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _vm.logsLoading
        ? _c("b-overlay", {
            staticStyle: { "min-height": "200px" },
            attrs: { show: _vm.logsLoading, variant: "white" },
          })
        : _vm._e(),
      _vm.worklogs.length > 0 &&
      _vm.totalRows > _vm.perPage &&
      !_vm.hidePagination
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "pl-5 ml-4",
                  attrs: { xl: _vm.hideSearchGroup ? "11" : "9" },
                },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      align: "center",
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.worklogs.length == 0 && !_vm.logsLoading
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c("b-col", { staticClass: "avo-text-info" }, [
                _vm._v(" No worklogs found "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }