var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "new-task",
        title: "New Task",
        "hide-footer": "",
        size: "md",
      },
      on: { hide: _vm.resetModal },
    },
    [
      _c(
        "div",
        { staticClass: "p-3" },
        [
          _vm.claim != null
            ? _c(
                "b-card",
                { staticClass: "claim-preview-card mb-3" },
                [
                  _c(
                    "b-row",
                    {},
                    [
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("CLAIM ID"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.claim_id)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("PAYER"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.payer_name)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("STATUS"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("div", {
                                class: ["status-icon", _vm.claim.state.short],
                              }),
                              _c("div", { staticClass: "data-text" }, [
                                _vm._v(_vm._s(_vm.claim.state.label) + " "),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("hr"),
                  _c(
                    "b-row",
                    {},
                    [
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("PATIENT"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.patient_name)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "label-text" }, [
                            _vm._v("PROVIDER"),
                          ]),
                          _c("div", { staticClass: "data-text" }, [
                            _vm._v(_vm._s(_vm.claim.provider_name)),
                          ]),
                        ]),
                      ]),
                      _vm.issues
                        ? _c("b-col", { attrs: { cols: "4" } }, [
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("div", { staticClass: "label-text" }, [
                                _vm._v("ISSUE"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "claim-flag" },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-1",
                                    attrs: { icon: "exclamation-circle" },
                                  }),
                                  _vm._v(_vm._s(_vm.claim.flag)),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _vm.showAlert
                ? _c(
                    "b-row",
                    { staticClass: "error-text" },
                    [
                      _c("b-col", [
                        _vm._v(" " + _vm._s(_vm.alertMessage) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasPermission("assign_claims") && _vm.workerId == null
                ? _c(
                    "label",
                    {
                      staticClass: "selecter-label",
                      attrs: { for: "task-worker" },
                    },
                    [_vm._v("Assigned")]
                  )
                : _vm._e(),
              _vm.hasPermission("assign_claims") && _vm.workerId == null
                ? _c(
                    "b-form-group",
                    { attrs: { id: "task-worker" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("worker-multiselect", {
                            attrs: {
                              "allow-empty": true,
                              "allow-unassigned": false,
                            },
                            model: {
                              value: _vm.task.assigned,
                              callback: function ($$v) {
                                _vm.$set(_vm.task, "assigned", $$v)
                              },
                              expression: "task.assigned",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.claim == null
                ? _c(
                    "label",
                    {
                      staticClass: "selecter-label",
                      attrs: { for: "task-claim" },
                    },
                    [_vm._v("Claim")]
                  )
                : _vm._e(),
              _vm.claim == null
                ? _c(
                    "b-form-group",
                    { attrs: { id: "task-claim" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("multiselect", {
                            attrs: {
                              openDirection: "bottom",
                              options: _vm.worker_claims,
                              placeholder: "Select a claim id or leave empty",
                              "show-labels": false,
                              "allow-empty": true,
                              internalSearch: true,
                            },
                            on: { open: _vm.clearAssigned },
                            model: {
                              value: _vm.task.claim_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.task, "claim_id", $$v)
                              },
                              expression: "task.claim_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "label",
                { staticClass: "selecter-label", attrs: { for: "task-title" } },
                [_vm._v("Title")]
              ),
              _c(
                "b-form-group",
                {
                  staticStyle: { "text-align": "left" },
                  attrs: { id: "task-title" },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "Enter task title",
                      state: _vm.fieldStates("title"),
                    },
                    model: {
                      value: _vm.task.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.task, "title", $$v)
                      },
                      expression: "task.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "selecter-label",
                          attrs: { for: "task-due-date" },
                        },
                        [_vm._v("Due Date")]
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { id: "task-due-date" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "date",
                              placeholder: "Choose a date",
                              state: _vm.fieldStates("dueDate"),
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              },
                            },
                            model: {
                              value: _vm.dueDate,
                              callback: function ($$v) {
                                _vm.dueDate = $$v
                              },
                              expression: "dueDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "selecter-label",
                          attrs: { for: "task-due-time" },
                        },
                        [_vm._v("Due Time")]
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { id: "task-due-time" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "time",
                              placeholder: "Choose a time",
                              state: _vm.fieldStates("dueTime"),
                              locale: "en",
                            },
                            model: {
                              value: _vm.dueTime,
                              callback: function ($$v) {
                                _vm.dueTime = $$v
                              },
                              expression: "dueTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "label",
                { staticClass: "selecter-label", attrs: { for: "task-body" } },
                [_vm._v("Description")]
              ),
              _c(
                "b-form-group",
                {
                  staticStyle: { "text-align": "left" },
                  attrs: { id: "task-body" },
                },
                [
                  _c("b-form-textarea", {
                    attrs: { placeholder: "Enter task description" },
                    model: {
                      value: _vm.task.body,
                      callback: function ($$v) {
                        _vm.$set(_vm.task, "body", $$v)
                      },
                      expression: "task.body",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v(" Create Task ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }